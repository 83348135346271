<template>
    <div class="bg-third-background w-full bg-fixed bg-center bg-cover bg-no-repeat h-56 md:h-96">

    </div>
</template>

<script>
export default {
    name: "ThirdBackground"
}
</script>

<style scoped>

</style>