<template>
    <div :class="bg" class="w-auto bg-blend-darken bg-no-repeat bg-auto bg-center mb-5 3sm:mb-12 h-96">
        <div class="flex justify-center items-center">
            <div class="mt-24 3sm:mt-28">
                <h2 class="text-center text-main-grey mt-36 md:mt-36 text-lg">{{title}}</h2>
                <p class="text-main-blue text-xs"><em>{{content}}</em></p>
            </div>
        </div>
        <div class="float-right mt-16 3sm:mt-24 inline transform scale-95 hover:scale-100 duration-300">
            <a rel="noopener noreferrer" target="_blank" :href="link" class="px-4 py-2 bg-main-green text-white border-2 border-main-green hover:bg-white hover:text-main-green transition-all duration-700 rounded-xl">Visiter le site</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "MobileProject",
    props: ['title', 'content', 'link', 'bg']
}
</script>

<style scoped>

</style>