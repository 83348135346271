<template>
    <section id="who" class="w-full xl:w-2/3 mb-5 mx-auto min-h-screen md:min-h-0 pt-10 xl:pt-24 px-2 md:px-0">
        <SectionTitle title="Qui je suis"></SectionTitle>
        <div class="lg:flex mt-5 xl:mt-12 lg:justify-center lg:items-center">
            <div class="w-full lg:w-1/3">
                <img alt="Julien Grade" data-aos="zoom-in" data-aos-duration="900" class="overflow-hidden object-cover border-4 md:border-8 p-1 md:p-2 bg-white border-main-blue h-44 w-44 md:h-64 md:w-64 mx-auto rounded-full" src="../../assets/img/cv-pic.svg" />
                <div class="flex justify-center mt-3 items-center">
                    <a title="Télécharger cv julien grade" aria-label="Télécharger le cv" download="cv-julien-grade.pdf" href="/files/cv.pdf" class="download-cv-button">
                        Télécharger le CV
                    </a>
                </div>
            </div>
            <div class="w-full mt-5 px-2 md:px-5 lg:mt-0 lg:w-2/3 overflow-hidden">
                <p data-aos="fade-left" data-aos-duration="600" class="overflow-hidden text-main-grey dark:text-white text-center md:text-left px-4 md:px-0 text-lg leading-10">
                    &nbsp;Je m'appelle Julien Grade, durant 15 ans j'ai été directeur commercial dans diverses grandes enseignes. Depuis 2010 je souhaite me reconvertir
                    dans le développement web, en 2019 j'ai pu saisir l'opportunité de suivre une formation en tant que développeur web et web mobile et ainsi obtenir
                    le titre professionnel correspondant. Après être monté en compétences au travers de stages en entreprises j'ai réalisé un  rêve de longue date, créer mon
                    entreprise. <br/>
                    &nbsp;Aujourd'hui je suis développeur web Symfony React Vuejs free-lance au sein de mon entreprise <a title="Aller vers le site de l'entreprise e-kigaï-dev" class="text-blue-500 dark:text-blue-300 inline-block" rel="noopener noreferrer" target="_blank" href="https://e-kigaï-dev.fr">e-kigaï-dev</a>
                </p>
            </div>
        </div>
        <div class="flex justify-center items-center space-x-12 xl:space-x-28 mt-12 3xl:mt-24 mb-5 xl:mb-12">
            <a title="Aller vers le profil Linkedin de Julien Grade" data-aos="zoom-in" data-aos-duration="700" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/julien-grade/" class="ml-3 text-main-blue dark:text-main-light dark:hover:text-white hover:text-main-light overflow-hidden">
                <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-10 h-10 xl:w-16 xl:h-16" viewBox="0 0 24 24">
                    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                    <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
            </a>
            <a title="Aller vers le profil Github de Julien Grade" data-aos="zoom-in" data-aos-duration="700" target="_blank" rel="noopener noreferrer" href="https://github.com/JulienGrade" class="text-main-blue hover:text-main-light dark:text-main-light dark:hover:text-white">
                <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" class="w-10 h-10 xl:w-16 xl:h-16" viewBox="0 0 24 24">
                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                </svg>
            </a>
            <a title="Aller vers le profil Twitter de Julien Grade" data-aos="zoom-in" data-aos-duration="700" target="_blank" rel="noopener noreferrer" href="https://twitter.com/gjulien8" class="ml-3 text-main-blue hover:text-main-light dark:text-main-light dark:hover:text-white">
                <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 xl:w-16 xl:h-16" viewBox="0 0 24 24">
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                </svg>
            </a>
        </div>
    </section>
</template>

<script>
    import SectionTitle from "../Elements/SectionTitle";

    export default {
        name: 'Who',
        components: {SectionTitle},
    };
</script>
