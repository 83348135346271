<template>
    <div v-show="!isLoaded">
        <PageLoader />

    </div>
    <div v-if="isLoaded" :class="appTheme">
        <Header></Header>
        <main>

            <Who></Who>
            <div class="hidden xl:block">
                <FirstBackground></FirstBackground>
            </div>
            <Skills></Skills>
            <div class="hidden xl:block">
                <SecondBackground></SecondBackground>
            </div>
            <Experience></Experience>
            <div class="hidden xl:block">
                <ThirdBackground></ThirdBackground>
            </div>
            <Projects></Projects>
            <Contact></Contact>
        </main>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header/Header";
import Who from "./components/Who/Who";
import FirstBackground from "./components/BannerSection/FirstBackground";
import Skills from "./components/Skills/Skills";
import SecondBackground from "./components/BannerSection/SecondBackground";
import Experience from "./components/Experience/Experience";
import ThirdBackground from "./components/BannerSection/ThirdBackground";
import Projects from "./components/Projects/Projects";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import PageLoader from "./components/PageLoader/PageLoader";



export default {
    name: 'App',
    components: {
        PageLoader,
        Footer,
        Contact,
        Projects,
        ThirdBackground,
        Experience,
        SecondBackground,
        Skills,
        FirstBackground,
        Who,
        Header,
    },
    data: () => {
        return {
            appTheme: localStorage.getItem('theme'),
            isLoaded: false
        };
    },
    mounted() {
        document.onreadystatechange = () => {
            if (document.readyState === "complete") {
                setTimeout(()=>this.isLoaded = true, 1000);
            }
        }
    },
}
</script>

<style>
#app{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
