<template>
    <div>
        <h2 data-aos="zoom-in" data-aos-duration="800" data-aos-delay="25" class="text-2xl font-bold text-center text-main-blue dark:text-main-light md:text-2xl xl:text-4xl">{{title}}</h2>
    </div>
</template>

<script>
export default {
    name: "SectionTitle",
    props: ['title']
}
</script>

<style scoped>

</style>