<template>
    <section id="contact" class="w-full mb-8 md:mb-16 xl:mb-28 xl:w-2/3 mx-auto pt-10 xl:pt-24 px-2 md:px-0">
        <hr/>
        <div class="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div class="mt-8 overflow-hidden">
                <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="p-6 mr-2 bg-gray-100 dark:bg-main-blue sm:rounded-lg">
                        <h1 class="text-4xl sm:text-5xl text-main-blue dark:text-main-light font-extrabold tracking-tight">
                            Contactez-moi !
                        </h1>
                        <p class="text-normal text-lg sm:text-2xl font-medium text-gray-600 dark:text-main-light mt-2">
                            Remplissez le formulaire pour entamer la discussion
                        </p>

                        <div class="flex items-center mt-8 text-gray-600 dark:text-main-light">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-main-grey dark:text-main-light">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-48">
                                Julien Grade, <br/>267 rue Achille Andris, Somain,
                                59490
                            </div>
                        </div>

                        <div class="flex items-center mt-4 text-gray-600 dark:text-main-light">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-main-grey dark:text-main-light">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                                +06 50664338
                            </div>
                        </div>

                        <div class="flex items-center mt-2 text-gray-600 dark:text-main-light">
                            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" class="w-8 h-8 text-main-grey dark:text-main-light">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                            </svg>
                            <div class="ml-4 text-md tracking-wide font-semibold w-40">
                                gradejulien@gmail.com
                            </div>
                        </div>
                    </div>

                    <form class="p-6 flex flex-col justify-center" @submit.prevent="sendEmail">
                        <div class="flex flex-col">
                            <label for="name" class="hidden">Votre Nom</label>
                            <input v-model="name" type="text" name="name" id="name" placeholder="Votre nom" class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-main-blue border border-gray-400 dark:border-gray-700 text-main-grey dark:text-main-light font-semibold focus:border-main-blue focus:outline-none">
                        </div>

                        <div class="flex flex-col mt-2">
                            <label for="email" class="hidden">Votre Email</label>
                            <input v-model="email" type="email" name="email" id="email" placeholder="Votre email" class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-main-blue border border-gray-400 dark:border-gray-700 text-main-grey dark:text-main-light font-semibold focus:border-main-blue focus:outline-none">
                        </div>

                        <div class="flex flex-col mt-2">
                            <label for="message" class="hidden">Votre message</label>
                            <textarea v-model="message" rows="4" placeholder="Votre message" name="message" id="message" class="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-main-blue border border-gray-400 dark:border-gray-700 text-main-grey dark:text-main-light font-semibold focus:border-main-blue focus:outline-none"></textarea>
                        </div>
                        <button type="submit" value="Send" class="md:w-32 flex justify-center items-center bg-main-blue hover:bg-main-light text-white font-bold py-3 px-6 rounded-lg mt-3 hover:text-main-blue transition-all duration-700">
                            Envoyer
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import emailjs from 'emailjs-com';
import {SERVICE_ID, TEMPLATE_ID, USER_ID} from "../../../config";


export default {
    name: 'Contact',
    data() {
        return {
            name: '',
            email: '',
            message: ''
        }
    },
    methods: {
        sendEmail(e) {
            try {
                emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target,
                    USER_ID, {
                        name: this.name,
                        email: this.email,
                        message: this.message
                    })

            } catch(error) {
                console.log({error})
            }
            // Reset form field
            this.name = ''
            this.email = ''
            this.message = ''
        },
    }
    };
</script>
