<template>
    <div class="sidebar">
        <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
        <transition name="slide">
            <div v-if="isPanelOpen"
                 class="sidebar-panel">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    methods: {
        closeSidebarPanel() {
            this.$store.commit('toggleNav')
        }
    },
    computed: {
        isPanelOpen(){
            return this.$store.state.isNavOpen
        }
    }
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active
{
    transition: transform 1s ease-in;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(-100%);
    transition: all 600ms ease-in 0s
}

.sidebar-backdrop {
    background-color: rgba(0,0,0,.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
}

.sidebar-panel {
    overflow-y: auto;
    background-color: #284B63;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    z-index: 999;
    padding: 3rem 20px 2rem 20px;
    width: 65%;
}
</style>