<template>
    <section id="experience" class="w-full mb-8 md:mb-16 xl:mb-28 xl:w-2/3 mx-auto pt-10 xl:pt-24 px-2 md:px-4 lg:px-0">
        <div class="mb-5 xl:mb-10">
            <SectionTitle title="Où j'ai exercé"></SectionTitle>
        </div>
        <div class="flex -mx-1 mb-5 my-5 xl:my-24 overflow-hidden">
            <div data-aos="fade-up-right" data-aos-duration="800" class="w-1/2 px-1">

                <a href="https://www.cesi.fr/" target="_blank" rel="noopener noreferrer" class="block mb-2 p-5 h-72 lg:h-32 rounded overflow-hidden transform transition-all duration-300 scale-100 hover:scale-95 bg-gray-200 bg-cesi-background bg-auto bg-no-repeat bg-center">
                    <h3 class="text-lg font-bold text-white leading-tight">CESI Lille</h3>
                </a>
                <a href="https://abramonage.com/" target="_blank" rel="noopener noreferrer" class="block mb-2 p-5 h-80 lg:h-48 rounded overflow-hidden transform transition-all bg-gray-50 duration-300 scale-100 hover:scale-95 bg-ab-ramonage-background bg-auto bg-center bg-no-repeat">
                    <h3 class="text-lg md:mt-60 md:ml-44 lg:mt-0 lg:ml-0 font-bold text-main-grey leading-tight">Ab Ramonage</h3>
                </a>
            </div>
            <div data-aos="fade-down-left" data-aos-duration="800" class="w-1/2 px-1">
                <a href="https://xn--e-kiga-dev-c9a.fr/" target="_blank" rel="noopener noreferrer" class="block mb-2 p-5 h-80 lg:h-48 bg-main-light rounded overflow-hidden transform transition-all duration-300 scale-100 hover:scale-95 bg-e-kigai-dev-background bg-auto bg-center bg-no-repeat">
                    <h3 class="text-lg font-bold text-main-grey leading-tight">e-kigaï-dev</h3>
                </a>
                <a  href="https://play.google.com/store/apps/details?id=com.coffee.sugar&hl=fr&gl=US" target="_blank" rel="noopener noreferrer" class="block h-72 lg:h-32 mb-2 p-5 rounded overflow-hidden transform transition-all duration-300 scale-100 hover:scale-95 bg-coffee-model-background bg-center bg-cover bg-no-repeat">
                    <h3 class="text-lg mt-8 md:mt-12 lg:mt-0 font-bold text-white leading-tight">Coffee Model</h3>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
    import SectionTitle from "../Elements/SectionTitle";

    export default {
        name: 'Experience',
        components: {
            SectionTitle
        }
    };
</script>
