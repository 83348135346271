import { createApp } from 'vue'
import App from './App.vue'
import { createStore } from 'vuex'
import VueKinesis from "vue-kinesis"
import VueSmoothScroll from 'v-smooth-scroll'



import AOS from 'aos'
import 'aos/dist/aos.css'

import './index.css'
import 'animate.css';

// Create a new store instance.
const store = createStore({
    state () {
        return {
            isNavOpen: false
        }
    },
    mutations: {
        toggleNav(state) {
            state.isNavOpen =! state.isNavOpen
        }
    }
})
const app = createApp(App);
app.use(store);
app.use(AOS.init());
app.use(VueKinesis);
app.use(VueSmoothScroll, {
    updateHistory: true,
    duration: 800
});
app.mount('#app')

// Gestion du mode Dark
const appTheme = localStorage.getItem('theme');

// Check what is the active theme
if (
    appTheme === 'dark' &&
    document.querySelector('body').classList.contains('app-theme')
) {
    document.querySelector('body').classList.add('bg-main-black');
} else {
    document.querySelector('body').classList.add('bg-white');
}
