<template>
    <section id="skills" class="w-full mb-8 md:mb-16 xl:mb-28 xl:w-2/3 mx-auto min-h-screen pt-10 xl:pt-24 px-2 md:px-6 lg:px-12 xl:px-0">
        <!-- Section title -->
        <div class="mb-5 xl:mb-10">
            <SectionTitle title="Ce que je sais"></SectionTitle>
        </div>
        <!-- SkillsModal section -->
        <SkillsModal></SkillsModal>
        <!-- Complementary skills and graduation section -->
        <div data-aos="zoom-in" data-aos-duration="700" class="overflow-hidden">
            <div class="w-full mt-5 mb-12 md:w-2/3 mx-auto bg-main-blue bg-opacity-40 border-2 px-2 shadow-xl border-main-blue border-opacity-40 hover:border-main-light transform duration-300 scale-95 hover:scale-100 hover:bg-opacity-100 hover:text-white dark:text-white dark:hover:text-main-light text-main-grey rounded-2xl">
                <div class="text-center p-6">
                    <h3 class="text-xl md:text-2xl">J'ai des notions en...</h3>
                    <p class="my-3">
                        Angular, MongoDb, ExpressJs, Ionic, Wordpress
                    </p>
                    <h3 class="text-xl md:text-2xl">Mes diplômes</h3>
                    <p class="my-3">
                        Bac Economie, Bts Management des unités commerciales, titre professionnel développeur web et web mobile, nombreux certificats de formations en ligne
                    </p>
                </div>
            </div>
        </div>
        <!-- Tools and Usual creation section -->
        <div class="lg:flex lg:justify-between lg:items-center lg:space-x-6">
            <SkillsCardMobile
                title="Ce que j'utilise au quotidien"
                content="PhpStorm, iTerm, GitHub, MacOs, Linux, Composer, Trello"
                bg="bg-tools-background"
            >
            </SkillsCardMobile>
            <SkillsCardsDesktop
                title="Ce que j'utilise au quotidien"
                content="PhpStorm, iTerm, GitHub, MacOs, Linux, Composer, Trello"
                bg="bg-tools-background"
            >
            </SkillsCardsDesktop>
            <SkillsCardsDesktop
                title="Ce que je crée au quotidien"
                content="Sites vitrine, sites e-commerce, blogs, applications, formations web"
                bg="bg-doing-background"
            >
            </SkillsCardsDesktop>
            <SkillsCardMobile
                title="Ce que je crée au quotidien"
                content="Sites vitrine, sites e-commerce, blogs, applications, formations web"
                bg="bg-doing-background"
            >
            </SkillsCardMobile>
        </div>
    </section>
</template>

<script>
    import SectionTitle from "../Elements/SectionTitle";
    import SkillsModal from "../SkillsModal/SkillsModal";
    import SkillsCardMobile from "../SkillsCard/SkillsCardMobile";
    import SkillsCardsDesktop from "../SkillsCard/SkillsCardsDesktop";

    export default {
        name: 'Skills',
        components: {
            SkillsCardsDesktop,
            SkillsCardMobile,
            SkillsModal,
            SectionTitle
        },
        data(){
            return{
                showFirst: true,
                showSecond: true
            }
        }
    };
</script>
