<template>
    <div data-aos-duration="800" data-aos="flip-up" class="overflow-hidden lg:hidden w-11/12 my-4 lg:my-0 rounded-xl cursor-pointer shadow-xl lg:w-1/2 mx-auto md:mx-5 group">
        <div :class="bg" class="h-96 md:h-64 p-12 bg-cover bg-center bg-no-repeat rounded-2xl">
            <h2 class="text-main-light py-4 lg:py-2 xl:py-10 text-xl xl:text-3xl text-center">{{title}}</h2>
            <div class="">
                <div>
                    <p class="px-4 lg:px-4 xl:px-6 text-main-light text-lg">
                        {{content}}
                    </p>
                </div>
                <div class="mt-3 lg:mt-4 xl:mt-12 lg:mr-4 xl:mr-8 flex justify-center md:flex md:justify-end">
                    <a class="py-2 px-4 lg:py-2 lg:px-4 xl:py-4 xl:px-8 rounded-full border-2 border-main-light bg-main-light hover:bg-main-grey transition-all duration-700 hover:text-main-light text-main-grey text-lg md:text-xl lg:text-sm" href="#contact">Me contacter</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SkillsCard',
        props: ['title', 'bg', 'content']
    };
</script>
