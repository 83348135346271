<template>
    <section id="projects" class="w-full mb-8 xl:w-2/3 mx-auto pt-10 xl:pt-24 px-2 md:px-0">
        <div class="mb-5 xl:mb-10">
            <SectionTitle title="Ce que je réalise"></SectionTitle>
        </div>
        <div class="hidden lg:block">
            <Desktopprojects></Desktopprojects>
        </div>
        <div class="w-full lg:hidden mx-auto">
            <MobileCarousel></MobileCarousel>
        </div>
    </section>
</template>

<script>


import SectionTitle from "../Elements/SectionTitle";
import MobileCarousel from "../MobileProjects/MobileCarousel";
import Desktopprojects from "../DesktopProjects/DesktopProjects";

    export default {
        name: 'Projects',
        components: {
            Desktopprojects,
            SectionTitle,
            MobileCarousel
        }
    };
</script>
