<template>
    <div class="hidden w-full my-4 md:my-0 card-zoom  rounded-xl cursor-pointer shadow-xl lg:w-1/2 md:mx-auto md:mx-5 group">
        <div :class="bg" class="card-zoom-image p-12 bg-cover bg-center bg-no-repeat rounded-2xl">
            <h2 class="text-main-light py-4 lg:py-2 xl:py-10 text-xl xl:text-3xl text-center">{{title}}</h2>
            <div class="opacity-0 group-hover:opacity-100  transition-all duration-1000">
                <div>
                    <p class="px-4 lg:px-4 xl:px-6 text-main-light text-lg">
                        {{content}}
                    </p>
                </div>
                <div class="mt-3 lg:mt-4 xl:mt-12 lg:mr-4 xl:mr-8 flex justify-center md:flex md:justify-end">
                    <a class="py-2 px-4 lg:py-2 lg:px-4 xl:py-4 xl:px-8 rounded-full border-2 border-main-light bg-main-light hover:bg-main-grey transition-all duration-700 hover:text-main-light text-main-grey text-lg md:text-xl lg:text-sm" href="#contact">Me contacter</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SkillsCardsDesktop",
    props: ['title', 'bg', 'content']
}
</script>

<style scoped>

</style>