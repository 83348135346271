<template>
    <header id="header">
        <div class="min-h-screen bg-main-blue ">
            <kinesis-container>
                <div class="hidden z-0 lg:block absolute top-16 left-32 animate__animated animate__fadeInLeft">
                    <kinesis-element class="block" :strength="40">
                        <img class="h-48 w-48 transform -rotate-6" alt="computer" src="../../assets/icones/computer.svg" />
                    </kinesis-element>
                </div>
                <div class="lg:hidden z-0 absolute top-16 left-12 computer-icon-position md:left-32 animate__animated animate__fadeInLeft">
                    <img class="h-12 w-12 header-icon md:h-36 md:w-36 transform -rotate-6" alt="computer" src="../../assets/icones/computer.svg" />
                </div>
                <div class="flex justify-center z-20 items-center min-h-screen">
                    <div class="px-2 block">
                        <h1 class="animate__animated animate__fadeInDownBig animate__slow text-3xl xl:text-5xl text-white text-center">Julien Grade Développeur web</h1>
                        <div class="mt-5 md:mt-10 xl:mt-14 space-y-3 md:space-y-0 md:flex md:justify-between md:items-center">
                            <div class="flex justify-center items-center">
                                <a title="Aller vers me contacter" href="#contact" class="header-button animate__animated animate__rotateInDownRight animate__slow">Me contacter</a>
                            </div>
                            <div class="flex justify-center items-center">
                                <a title="Aller vers la section qui je suis" href="#who" class="header-button animate__animated animate__rotateInUpLeft animate__slow">Me découvrir</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hidden z-0 lg:block absolute top-32 right-32 animate__animated animate__fadeInRight">
                    <kinesis-element class="block" :strength="30">
                        <img class="h-32 w-32 transform rotate-12" alt="computer" src="../../assets/icones/desktop.svg" />
                    </kinesis-element>
                </div>
                <div class="lg:hidden z-0 absolute top-24 md:top-32 desktop-icon-position right-14 md:right-32 animate__animated animate__fadeInRight">
                    <img class="h-12 w-12 header-icon md:h-36 md:w-36 portrait:w-12 portrait:h-12 transform rotate-12 z-10" alt="computer" src="../../assets/icones/desktop.svg" />
                </div>
                <div class="hidden z-0 lg:block absolute bottom-16 left-60 animate__animated animate__fadeInLeft">
                    <kinesis-element class="block" :strength="80">
                        <img class="h-36 w-36 transform rotate-12" alt="computer" src="../../assets/icones/mouse.svg" />
                    </kinesis-element>
                </div>
                <div class="lg:hidden z-0 absolute bottom-28 left-12 mouse-icon-position md:left-24 animate__animated animate__fadeInLeft">
                    <img class="h-12 w-12 header-icon md:h-36 md:w-36 transform rotate-12" alt="computer" src="../../assets/icones/mouse.svg" />
                </div>
                <div class="hidden z-0 lg:block absolute bottom-8 right-60 animate__animated animate__fadeInRight">
                    <kinesis-element class="block" :strength="80">
                        <img class="h-36 w-36 transform rotate-6" alt="computer" src="../../assets/icones/tablet.svg" />
                    </kinesis-element>
                </div>
                <div class="lg:hidden z-0 absolute bottom-16 tablet-icon-position md:bottom-32 right-16 animate__animated animate__fadeInRight">
                    <img class="h-12 w-12 header-icon md:h-36 md:w-36 transform rotate-6" alt="computer" src="../../assets/icones/tablet.svg" />
                </div>
            </kinesis-container>
        </div>
    </header>
    <navbar></navbar>
</template>

<script>
    import Navbar from "../Navbar/Navbar";
    import { KinesisContainer, KinesisElement} from 'vue-kinesis';

    export default {
        name: 'Header',
        components:{
            Navbar,
            KinesisContainer,
            KinesisElement
        },
        data(){
            return{
                computer: '../../assets/icones/computer.svg'
            }
        }
    };
</script>
