<template>
    <section>
        <!-- Navigation between the Modals -->
        <div class="flex justify-center items-center">
            <!-- Back-end button -->
            <button v-if="showModal1" class="underline text-main-blue dark:text-main-light text-lg xl:text-3xl pr-0" @click="[showModal1 = true, showModal2=false]">Back-end</button>
            <button v-if="showModal2" class="text-main-blue animate-pulse dark:text-main-light text-lg xl:text-3xl xl:mr-3 pr-0" @click="[showModal1 = true, showModal2=false]">Back-end</button>

            <!-- Arrow logo from left with darkmode -->
            <img v-show="theme==='light'" class="w-12 xl:w-20 -ml-5 xl:ml-2" v-if="showModal1" alt="Flèche indiquant vers le bas en partant de la gauche" src="../../assets/icones/arrow-left.svg"/>
            <img v-show="theme==='dark'" class="w-12 xl:w-20 -ml-5 xl:ml-2" v-if="showModal1" alt="Flèche indiquant vers le bas en partant de la gauche" src="../../assets/icones/arrow-left-white.svg"/>

            <!-- Arrow logo from right with darkmode -->
            <img v-show="theme==='light'" class="w-12 xl:w-20 -mr-5 xl:mr-2" v-if="showModal2" alt="Flèche indiquant vers le bas en partant de la droite" src="../../assets/icones/arrow-right.svg"/>
            <img v-show="theme==='dark'" class="w-12 xl:w-20 -mr-5 xl:mr-2" v-if="showModal2" alt="Flèche indiquant vers le bas en partant de la droite" src="../../assets/icones/arrow-right-white.svg"/>

            <!-- Front-end button -->
            <button v-if="showModal2" class="underline text-main-blue dark:text-main-light xl:w-auto text-lg xl:text-3xl pl-O" @click="[showModal2 = true, showModal1=false]">Front-end</button>
            <button v-if="showModal1" class="text-main-blue animate-pulse dark:text-main-light text-lg xl:w-auto xl:text-3xl xl:ml-3 pl-O" @click="[showModal2 = true, showModal1=false]">Front-end</button>

        </div>
        <!-- Back-end modal content -->
        <article class="bg-main-blue mt-5 flex items-center justify-center text-center py-4 h-96 rounded-lg text-main-light animate__animated animate__backInUp" v-show="showModal1">
            <div class="xl:flex xl:justify-center xl:space-x-4">
                <!-- Languages section -->
                <div class="xl:w-1/4 animate__animated animate__zoomIn animate__slow">
                    <h3 class="text-lg xl:text-3xl text-center underline">Langages</h3>
                    <p class="text-white xl:text-4xl py-1">Php, Sql</p>
                </div>
                <!-- Frameworks section -->
                <div class="xl:w-2/4 animate__animated animate__zoomIn animate__slow">
                    <h3 class="text-lg xl:text-3xl text-center underline">Frameworks</h3>
                    <p class="text-white xl:text-4xl py-1">Symfony, Laravel</p>
                    <p class="px-2 italic xl:text-3xl text-sm">Gestion base de données, SQL, Dql, Sqlite<br/>
                        Création Api, ApiPlatform, e-commerce Sylius<br/>
                        Tests unitaires et fonctionnels<br/>
                    </p>
                </div>
                <!-- Tools section -->
                <div class="xl:w-1/4 animate__animated animate__zoomIn animate__slow">
                    <h3 class="text-lg xl:text-3xl text-center underline">Outils</h3>
                    <p class="text-white xl:text-4xl py-1">
                        PhpUnit, Postman, Stripe
                    </p>
                </div>
            </div>
        </article>
        <!-- Front-end modal content -->
        <article class="bg-main-blue mt-5 flex justify-center items-center text-center py-4 rounded-lg h-96 text-main-light animate__animated animate__backInUp" v-show="showModal2">
            <div class="xl:flex xl:justify-center xl:space-x-4">
                <!-- Languages section -->
                <div class="xl:w-1/4 animate__animated animate__zoomIn animate__slow">
                    <h3 class="text-lg xl:text-3xl text-center underline">Langages</h3>
                    <p class="text-white xl:text-4xl py-1">HTML, CSS, Javascript</p>
                </div>
                <!-- Frameworks section -->
                <div class="xl:w-2/4 animate__animated animate__zoomIn animate__slow">
                    <h3 class="text-lg xl:text-3xl text-center underline">Frameworks</h3>
                    <p class="text-white xl:text-4xl py-1">React, Vuejs, Bootstrap, Tailwindcss</p>
                    <p class="px-2 italic xl:text-3xl text-sm">Npm, axios, déploiement<br />
                        Utilisation Redux, Vueex<br/>
                        Gestion des tests avec Jestjs
                    </p>
                </div>
                <!-- Tools section -->
                <div class="xl:w-1/4 animate__animated animate__zoomIn animate__slow">
                    <h3 class="text-lg xl:text-3xl text-center underline">Outils</h3>
                    <p class="text-white xl:text-4xl py-1">
                        Twig, HandleBars, Photoshop, Illustrator, AdobeXD
                    </p>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
    export default {
        name: 'Skillsmodal',
        data(){
            return{
                showModal1: true,
                showModal2: false,
                theme: localStorage.getItem('theme')
            }
        },
        beforeUnmount() {
            window.removeEventListener("click", this.click)
        }
    };
</script>

<style>

</style>
