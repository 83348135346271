<template>
    <!-- UI swipe indicator -->
    <div class="">
        <p class="text-center text-main-blue dark:text-main-light">Faites glisser pour parcourir</p>
    </div>
    <div class="flex -mb-20 3sm:-mb-10 md:mb-0 md:block  pt-4 justify-center items-center">
        <img v-show="theme==='light'" class="h-10 block w-10 md:mx-auto md:mb-12 animate__animated animate__wobble animate__infinite animate__slower" alt="swipe" src="../../assets/icones/swipe.svg" />
        <img v-show="theme==='dark'" class="h-10 block w-10 md:mx-auto md:mb-12 animate__animated animate__wobble animate__infinite animate__slower" alt="swipe" src="../../assets/icones/swipe-white.svg" />
    </div>
    <!-- Project Carousel section -->
    <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
    >
        <!-- First project -->
        <swiper-slide>
            <MobileProject
                title="Pearle-dream"
                content="e-commerce de vente de bijoux"
                link="https://bijouterie-demo.xn--e-kiga-dev-c9a.fr/"
                bg="bg-mockup-bijoux">
            </MobileProject>
        </swiper-slide>
        <!-- Second project -->
        <swiper-slide>
            <MobileProject
                title="AB Ramonage"
                content="Site vitrine pour prise de contact"
                link="https://abramonage.com/"
                bg="bg-mockup-ramonage">
            </MobileProject>
        </swiper-slide>
        <!-- Third project -->
        <swiper-slide>
            <MobileProject
                title="La Botega Ristorante"
                content="e-commerce Click&Collect"
                link="https://v2-pizza.xn--e-kiga-dev-c9a.fr/"
                bg="bg-mockup-pizzeria">
            </MobileProject>
        </swiper-slide>
        <!-- Fourth project -->
        <swiper-slide>
            <MobileProject
                title="Euratech Kids"
                content="e-commerce de réservation"
                link="https://euratech-demo.xn--e-kiga-dev-c9a.fr/"
                bg="bg-mockup-euratech">
            </MobileProject>
        </swiper-slide>
        <!-- Fifth project -->
        <swiper-slide>
            <MobileProject
                title="French fries"
                content="Site statique pour restaurant"
                link="https://v1-friterie.xn--e-kiga-dev-c9a.fr/"
                bg="bg-mockup-friterie">
            </MobileProject>
        </swiper-slide>
        <!-- Sixth project -->
        <swiper-slide>
            <MobileProject
                title="Shadow"
                content="Site type blog"
                link="https://shadow.juliengrade.fr/"
                bg="bg-mockup-shadow">
            </MobileProject>
        </swiper-slide>
    </swiper>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide } from 'swiper/vue';
import {Navigation, EffectFade} from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import MobileProject from "./MobileProject";

    export default {
        name: 'Mobilecarousel',
        data(){
            return{
                theme: localStorage.getItem('theme')
            }
        },
        components: {
            MobileProject,
            Swiper,
            SwiperSlide,
        },
        setup() {

            return {
                modules: [Navigation, EffectFade],
            };
        },
    };
</script>
