<template>
    <div class="flex flex-col sticky top-0 z-20">
        <nav class="bg-main-blue shadow-lg ">
            <div class="w-full mx-auto px-4">
                <div class="flex">
                    <div class="flex xl:space-x-4 2xl:space-x-7">
                        <!-- Logo -->
                        <div>
                            <a href="#" class="flex items-center py-4 md:px-2">
                                <h1 class="text-main-light logo-text md:text-3xl lg:text-2xl">Julien Grade Développeur web</h1>
                            </a>
                        </div>
                        <!-- Menu Desktop -->
                        <div class="hidden xl:flex items-center xl:pl-32 2xl:pl-60 space-x-2">
                            <a class="desktop-navigation-item" href="#who" title="Home" v-smooth-scroll>Qui je suis</a>
                            <a class="desktop-navigation-item" href="#skills" title="Home" v-smooth-scroll>Ce que je sais</a>
                            <a class="desktop-navigation-item" href="#experience" title="Home" v-smooth-scroll>Ou j'ai exercé</a>
                            <a class="desktop-navigation-item" href="#projects" title="Home" v-smooth-scroll>Ce que je réalise</a>
                            <a class="desktop-navigation-item" href="#contact" title="Home" v-smooth-scroll>Me contacter</a>
                        </div>
                        <div class="absolute hidden lg:block h-12 w-12 top-1 right-12">
                            <ThemeSwitcher :theme="theme"
                                           @themeChanged="updateTheme"
                            ></ThemeSwitcher>
                        </div>
                        <!-- Mobile menu button -->
                        <div class="xl:hidden flex items-center absolute right-2 top-2">
                            <burger></burger>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Menu version mobile sidebar -->
            <sidebar>
                <burger class="float-right"></burger>
                <ul class="text-main-light hover:text-white sidebar-panel-nav">
                    <li data-aos="fade-right"
                        data-aos-offset="500"
                        data-aos-easing="ease-in-sine" class="active"><a @click="closeSidebarPanel" href="#who" v-smooth-scroll class="block text-sm sm:text-xl px-2 py-4 hover:text-white font-semibold">Qui je suis</a></li>
                    <li data-aos="fade-right"
                        data-aos-offset="500"
                        data-aos-easing="ease-in-sine"><a @click="closeSidebarPanel" href="#skills" v-smooth-scroll class="block text-sm sm:text-xl px-2 py-4 hover:text-white transition duration-300">Ce que je sais</a></li>
                    <li data-aos="fade-right"
                        data-aos-offset="500"
                        data-aos-easing="ease-in-sine"><a @click="closeSidebarPanel" href="#experience" v-smooth-scroll class="block text-sm sm:text-xl px-2 py-4 hover:text-white transition duration-300">Ou j'ai exercé</a></li>
                    <li data-aos="fade-right"
                        data-aos-offset="500"
                        data-aos-easing="ease-in-sine"><a @click="closeSidebarPanel" href="#projects" v-smooth-scroll class="block text-sm sm:text-xl px-2 py-4 hover:text-white transition duration-300">Ce que je réalise</a></li>
                    <li data-aos="fade-right"
                        data-aos-offset="500"
                        data-aos-easing="ease-in-sine"><a @click="closeSidebarPanel" href="#contact" v-smooth-scroll class="block text-sm sm:text-xl px-2 py-4 hover:text-white transition duration-300">Me contacter</a></li>
                </ul>
                <div class="mt-16">
                    <p class="text-main-light text-center">Dark Mode</p>
                </div>
                <div class="flex justify-center">
                    <ThemeSwitcher :theme="theme"
                                   @themeChanged="updateTheme"
                    ></ThemeSwitcher>
                </div>
            </sidebar>
        </nav>
    </div>
</template>

<script>
    import Burger from "./Burger";
    import Sidebar from "./Sidebar";
    import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";

    export default {
        name: 'Navbar',
        components: {
            ThemeSwitcher,
            Burger,
            Sidebar
        },
        data() {
            return {
                showMenu: false,
                theme: '',
            }
        },
        created() {
            this.theme = localStorage.getItem('theme') || 'light';
        },
        mounted() {
            this.theme = localStorage.getItem('theme') || 'light';
        },
        methods: {
            closeSidebarPanel() {
                this.$store.commit('toggleNav')
            },
            updateTheme(theme) {
                this.theme = theme;
            },
        },
        computed: {
            isPanelOpen(){
                return this.$store.state.isNavOpen
            }
        },
        beforeUnmount() {
            window.removeEventListener('click', this.click)
        }
    };
</script>
